import * as React from 'react';
import { graphql } from 'gatsby';
import BeneathHeader from '@/components/BeneathHeader';
import BlockContent from '@/components/BlockContent';
import BlockContentContainer from '@/components/BlockContentContainer';
import Container from '@/components/Container';
import Cover, { CoverProps } from '@/components/Cover';
import HeaderImage from '@/components/HeaderImage';
import MetalTextScrollable from '@/components/MetalTextScrollable';
import Seo from '@/components/Seo';
import StoryCarousel from '@/components/StoryCarousel';
import Layout from '@/layouts/default';

export interface DivisionTemplateProps {
  pageContext: {
    language: string;
    languageLinks: LanguageLinks;
  };
  data: {
    division: Pick<
      GatsbyTypes.SanityDivision,
      'id' | 'key' | 'title' | 'description' | 'i18n_lang' | '_rawBody'
    > & {
      readonly slug: Pick<GatsbyTypes.SanitySlug, 'current'>;
      readonly meta: Pick<GatsbyTypes.SanityMeta, 'title' | 'description'>;
      readonly mainMedia: CoverProps['media'];
    };
    stories: GatsbyTypes.DivisionPageQuery['stories'];
  };
}

const DivsionTemplate: React.FC<DivisionTemplateProps> = ({
  pageContext: { languageLinks },
  data: { division, stories },
}) => (
  <Layout languageLinks={languageLinks}>
    <Seo
      title={division!.meta!.title!}
      description={division!.meta!.description}
      image={division!.mainMedia}
    />
    <BeneathHeader>
      <header
        className={`text-white ${
          Boolean(stories.edges.length)
            ? 'aspect-w-12 aspect-h-15 sm:aspect-w-16 md:aspect-h-9 lg:aspect-h-7'
            : 'aspect-w-16 aspect-h-9 md:aspect-h-7 lg:aspect-h-5'
        }`}
      >
        <div
          className={`flex flex-col items-center justify-center ${
            Boolean(stories.edges.length) ? 'pb-lg-fluid' : ''
          }`}
        >
          <HeaderImage position="absolute" className="inset-0 z-0">
            <Cover
              className="absolute inset-0"
              media={division.mainMedia}
              width={480}
              height={270}
            />
            <div className="absolute inset-x-0 bottom-0 z-40 top-1/2 bg-gradient-to-t from-blue-950 mix-blend-multiply opacity-90" />
          </HeaderImage>
          <Container
            className={`relative z-10 flex flex-col items-center justify-center text-center ${
              Boolean(stories.edges.length)
                ? 'pt-xl-fluid pb-3xl-fluid'
                : 'py-lg-fluid'
            }`}
          >
            <h1 className="sr-only">{division.title}</h1>
            <MetalTextScrollable
              className="font-normal leading-none text-4xl-fluid max-w-prose-xs sm:text-4xl-fluid sm:leading-none"
              top={0.25}
              bottom={0.25}
              lightModeSupport={false}
            >
              {division.description}
            </MetalTextScrollable>
          </Container>
        </div>
      </header>
    </BeneathHeader>
    {Boolean(stories.edges.length) && (
      <StoryCarousel
        stories={stories.edges}
        className="relative z-20 -mt-5xl-fluid mb-2xl-fluid"
      />
    )}
    <BlockContentContainer>
      <BlockContent blocks={division?._rawBody ?? []} />
    </BlockContentContainer>
  </Layout>
);

export default DivsionTemplate;

export const query = graphql`
  query DivisionPage($language: String!, $id: String!) {
    division: sanityDivision(id: { eq: $id }) {
      id
      key
      title
      description
      i18n_lang
      slug {
        current
      }
      meta {
        title
        description
      }
      mainMedia {
        ...SanityCoverFields
      }
      _rawBody(resolveReferences: { maxDepth: 10 })
    }
    stories: allSanityStory(
      filter: {
        i18n_lang: { eq: $language }
        hasParentItem: { eq: false }
        division: { id: { eq: $id } }
      }
      sort: { fields: priority, order: DESC }
    ) {
      edges {
        node {
          ...StoryCardFields
        }
      }
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      ...LocaleStrings
    }
  }
`;
